<template>
  <PageWithLayout>
    <div id="dkWrap" :class="['wrap_account', menuIdClass]">
      <!-- position:relative 적용 / 레이아웃 관련 클래스 적용 -->
      <comm-loading />
      <!-- kakaoHead -->
      <!-- <comm-header /> -->
      <hr class="hide" />
      <div id="dkContent" class="cont_doc" role="main">
        <div id="cMain">
          <!-- kakaoGNB -->
          <comm-gnb />
          <comm-article />
        </div>
        <!-- // cMain -->
      </div>
      <!-- // 2019-10-15 수정 끝 -->
      <!-- Layer -->
      <keep-alive>
        <component :is="firstLayer" />
      </keep-alive>
      <component :is="secondLayer" />
      <component :is="commToast" />
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import CommLoading from "./CommLoading";

import CommGnb from "./CommGnb";
import CommArticle from "./CommArticle";
import CommLayerAlert from "@/_approval/components/common/CommLayerAlert";
import CommLayerConfirm from "@/_approval/components/common/CommLayerConfirm";
import CommLayerPopup from "@/_approval/components/common/CommLayerPopup";
import CommToast from "@/_approval/components/common/CommToast";
import { mapState, mapGetters } from "vuex";

export default {
  name: "CommLayout",
  components: {
    PageWithLayout,
    CommLoading,

    CommGnb,
    CommArticle,
    CommLayerAlert,
    CommLayerConfirm,
    CommLayerPopup,
    CommToast,
  },
  computed: {
    ...mapState("CommStore", {
      toast: (state) => {
        return state.toast;
      },
    }),
    ...mapGetters("CommStore", {
      layer: "getLayer",
    }),
    firstLayer() {
      if (this.layer.order === 1) {
        return this.getComponent(this.layer.type);
      } else if (this.layer.order === 2) {
        let first = this.$store.state.CommStore.layer.first;
        return this.getComponent(first.type);
      }
    },
    secondLayer() {
      if (this.layer.order === 2) {
        return this.getComponent(this.layer.type);
      }
    },
    commToast() {
      if (this.toast.isShow) {
        return "comm-toast";
      }
    },
    menuIdClass() {
      return "wrap_account_" + this.$route.meta.menuId;
    },
  },

  created() {
    this._approvalCommonStyle = document.createElement("link");
    this._approvalCommonStyle.type = "text/css";
    this._approvalCommonStyle.rel = "stylesheet";
    this._approvalCommonStyle.href = "/assets/css/approval/_approvalCommon.css";
    document.head.append(this._approvalCommonStyle);
    this._approvalAccountStyle = document.createElement("link");
    this._approvalAccountStyle.type = "text/css";
    this._approvalAccountStyle.rel = "stylesheet";
    this._approvalAccountStyle.href = "/assets/css/approval/_approvalAccount.css";
    document.head.append(this._approvalAccountStyle);
    this.flatpickerStyle = document.createElement("link");
    this.flatpickerStyle.type = "text/css";
    this.flatpickerStyle.rel = "stylesheet";
    this.flatpickerStyle.href = "/assets/css/approval/flatpicker.css";
    document.head.append(this.flatpickerStyle);
    // this.slickStyle = document.createElement('link');
    // this.slickStyle.type = 'text/css';
    // this.slickStyle.rel = 'stylesheet';
    // this.slickStyle.href = '/ke/assets/css/slick.css';
    // document.head.append(this.slickStyle);
  },

  beforeDestroy() {
    this._approvalCommonStyle.remove();
    this._approvalAccountStyle.remove();
    this.flatpickerStyle.remove();
    // this.slickStyle.remove();
  },

  methods: {
    getComponent(layerType) {
      switch (layerType) {
        case "alert":
          return "comm-layer-alert";
        case "confirm":
          return "comm-layer-confirm";
        case "popup":
          return "comm-layer-popup";
      }
    },
  },
};
</script>

<style scoped>
.dk_foot {
  height: 32px;
  border-top: 1px solid #e1e1e1;
  background-color: #fff;
}
.dk_foot .txt_copy {
  display: block;
  padding: 7px 0 8px;
  font-size: 11px;
  font-weight: 500;
  line-height: 17px;
  color: #bbb;
  text-align: center;
}
</style>
